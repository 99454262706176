import React, { useState } from "react";
import axios from "axios";
import "./index.css";
import timoney from "../../assets/images/timoney.jpeg";
import assistance from "../../assets/images/assistance.jpeg";
import hero3 from "../../assets/images/hero3.jpg";

import { Button } from "@mui/base";
// import { border } from "@mui/system";
const Home = () => {
  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
    // console.log(width);
  };
  const { width } = useViewport();
  // const { height } = useViewport();
  const breakpointWidth = 450;
  const breakpointWidthMin = 333;
  const handleRedirect = () => {
    window.location.href = "/"; // change '/home' to the actual home page URL
  };

  const handleContactUs = () => {
    window.location.href = `mailto:?subject=Nouveau contact via Solution&body=Informations du Formulaire : ${contactCompany};${contactEmail};${contactFirstName};${contactLasttName};${contactCity};${contactPostcode};${contactTelephone};${contactEmailObject}${contactMessage}`;
  };

  const bookingData = {
    parking: {
      ville: "Paris",
    },
  };
  const [contactEmail, setContactEmail] = useState("");
  const [contactTelephone, setContactTelephone] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLasttName, setContactLasttName] = useState("");
  const [contactCompany, setContactCompany] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [contactEmailObject, setContactEmailObject] = useState("");
  const [contactPostcode, setContactPostcode] = useState("");
  const [contactCity, setContactCity] = useState("");

  const [contactData, setContactData] = useState({
    nom: "",
    société: "",
    email: "",
    mobile: "",
    message: "",
    codepostal: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
    // console.log(user);
  };

  return (
    <div className="solution-container">
    {/* <h1>{width}</h1> */}
      {/* <div className="payment-success-container-optionsPayment"> */}
      <h1 className="solution-head-title"
              style={width<breakpointWidth ? {fontSize: 50} : null}

      >
        La nouvelle façon de louer un parking
      </h1>
      {/* <div className="payment-success-textPayment">Parkyu</div>
      <div>
        <BookingThumbnail />
      </div> */}
      <p className="solution-intro-text">
        Comparez, réservez et profitez des meilleures places de parking au plus
        près de votre destination.
      </p>
      <div style={{ alignSelf: "center" }}>
        <img className="timoney" src={hero3} alt="illustration" 
        style={width<breakpointWidth ? {height: 250} : null}
        />
      </div>
      <h2 className="solution-section-title-text">
        Votre place est sur Parkyu
      </h2>
      <p className="solution-intro-text">
        Que vous recherchiez un garage pour votre moto ou une borne pour
        recharger votre voiture électrique, Parkyu vous permet de trouver le
        parking parfait pour tous vos déplacements.
      </p>
      {/* <div
        style={{
          border: "solid blue 2px",
          // width: "inherit",
          // margin: "auto",
          // alignSelf: "flex-start",
          justifyContent: "flex-start",
          textAlign: "start",
        }}
      > */}

      {/* </div> */}

      <p className="solution-intro-text">
        Vous avez une place libre dans votre garage, votre box ou votre parking
        ? Vous pouvez maintenant la louer à la demande aux voitures, vélos et
        deux-roues autour de vous.
      </p>
      {/* <div className="buttonContainerPayment">
        <div
          className="dashboardButtonPayment"
          onClick={() => {
            console.log(width);
          }}
        >
          Accéder à ma réservation
        </div>
        <div className="dashboard-button-spacerPayment"></div>
        <div className="dashboardButtonPayment">
          Réserver une nouvelle place{" "}
        </div>
      </div> */}
      <div style={{ alignSelf: "center" }}>
        <img className="timoney" src={timoney} alt="illustration" 
        style={width<breakpointWidth ? {height: 250} : null}

        />
      </div>

      <h2 className="solution-section-title-text">
        Simple. Pratique. Rentable.
      </h2>
      <p className="solution-intro-text">
        Profitez d'une solution complète qui ne vous coûtera rien avant de vous
        avoir rapporté
      </p>
      {/* <div className="smallTitle-container"> */}
        <h3 className="solution-section-small-title-text">
          Simplifiez la gestion de votre parking
        </h3>
      {/* </div> */}

      <p className="solution-intro-text">
        Facilitez la réservation de places de parking par les clients de votre
        établissement. Suivez l’occupation en temps réel de votre parking et
        gagnez un temps précieux pour votre activité.
      </p>
      {/* <div className="smallTitle-container"> */}
        <h3 className="solution-section-small-title-text">
          Optimisez l’occupation de vos places
        </h3>
      {/* </div> */}
      <p className="solution-intro-text">
        Gardez votre parking réservé à votre clientèle uniquement ou proposez
        certaines places en location à la demande lors d’événements ou aux
        visiteurs de passage dans votre ville.
      </p>
      {/* <div className="smallTitle-container"> */}
        <h3 className="solution-section-small-title-text">
          Une tarification adaptée à votre activité
        </h3>
      {/* </div> */}
      <p className="solution-intro-text">
        Accédez à toutes les fonctionnalités de Parkyu sans aucun coût
        d’installation, abonnement ou frais cachés.
      </p>
      <div style={{ alignSelf: "center" }}>
        <img className="timoney" src={assistance} alt="illustration" 
        style={width<breakpointWidth ? {height: 250} : null}
        />
      </div>
      <h2 className="solution-section-title-text">
        Nos équipes peuvent vous aider
      </h2>
      <p className="solution-intro-text"
      style={width<breakpointWidth ? {fontSize: 22, maxWidth:"90%"} : null}>
        Pour vous lancer plus rapidement sur Parkyu grâce à une aide
        personnalisée
      </p>
      <p className="solution-intro-text"
      style={width<breakpointWidth ? {fontSize: 22, maxWidth:"90%"} : null}>
        Pour vous familiariser avec la solution Parkyu via des démonstrations et
        des tutoriels
      </p>
      <p className="solution-intro-text"
      style={width<breakpointWidth ? {fontSize: 22, maxWidth:"90%"} : null}>
      Pour concevoir avec nos experts une solution qui répond à toutes vos
        attentes
      </p>
      <div className="contact-us-form-solution"
       style={width<breakpointWidthMin ? {paddingInline: "1%"} : null}
      >
        <h3
          className="solution-section-title-text-contact"
          style={{ fontFamily: "Lexend" }}
        >
          Prenez contact avec nous
        </h3>
        <h2
          className="solution-section-subtitle-text-contact"
          style={{ fontFamily: "Lexend" }}
        >
          Nos experts sont là pour vous répondre
        </h2>

        <div className="text-input-solution"
                  style={width<breakpointWidth ? {width:"100%", borderStyle: "solid", borderColor:"red", borderWidth:'0px'} : null}

        >
          <h3
                                  style={width<breakpointWidth ? {fontSize: 20} : null}

          >Nom</h3>
          <div className="input-field-solution"

          >
            <input
              type="string"
              id="nom"
              name="nom"
              //   placeholder="ex : 75000"
              // placeholder={profileData.nom ? profileData.nom : "ex: Dominique"}
              value={contactData.nom}
              onChange={handleInputChange}
              
            />
          </div>
        </div>
        <div className="text-input-solution">
          <h3
                                  style={width<breakpointWidth ? {fontSize: 20} : null}
                                  >Société</h3>
          <div className="input-field-solution"
                  // style={width<breakpointWidthMin ? {width: "40%"} : null}

          >
            <input
              type="text"
              id="société"
              name="société"
              //   placeholder="Société"
              // placeholder={profileData.nom ? profileData.nom : "ex: Dominique"}
              value={contactData.société}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="text-input-solution">
          <h3
                                  style={width<breakpointWidth ? {fontSize: 20} : null}
                                  >Email</h3>
          <div className="input-field-solution">
            <input
              type="email"
              id="email"
              name="email"
              //   placeholder="Email"
              //   required
              // placeholder={profileData.nom ? profileData.nom : "ex: Dominique"}
              value={contactData.email}
              onChange={handleInputChange}
              style={{ color: "#fff" }}
            />
          </div>
        </div>
        <div className="text-input-solution">
          <h3
                                  style={width<breakpointWidth ? {fontSize: 20} : null}
                                  >Mobile</h3>
          <div className="input-field-solution">
            <input
              type="tel"
              id="mobile"
              name="mobile"
              //   placeholder="Mobile"
              // placeholder={profileData.nom ? profileData.nom : "ex: Dominique"}
              value={contactData.mobile}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="text-input-solution">
          <h3
                        style={width<breakpointWidth ? {fontSize: 20} : null}

          >Code Postal</h3>
          <div className="input-field-solution">
            <input
              type="tel"
              id="codepostal"
              name="codepostal"
              //   placeholder="ex : 75000"
              // placeholder={profileData.nom ? profileData.nom : "ex: Dominique"}
              value={contactData.codepostal}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="text-input-solution-message">
          <div className="input-field-solution-message">
            <textarea
              type="text"
              rows="16"
              cols="26"
              id="message"
              name="message"
              placeholder="Ajoutez votre message ici (Facultatif)"
              // placeholder={profileData.nom ? profileData.nom : "ex: Dominique"}
              value={contactData.message}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBlock: "1%",
            marginTop: "2%",
            // height: "44px",
            // fontSize: "25px",
            // backgroundColor: "#0077",
          }}
        >
          <Button
            onClick={async () => {
              if (contactData.email && contactData.nom) {
                try {
                  const emailResponse = await axios.post(
                    // `https://git.heroku.com/parkorner.git/email/register`,
                    `https://parkorner-84d5e1ab268b.herokuapp.com/email/register`,

                    contactData,

                    {
                      headers: {
                        "Access-Control-Allow-Origin": "https://parkorner.com",
                        // "Access-Control-Allow-Origin": "http://localhost:3002",
                        "Access-Control-Allow-Methods":
                          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
                      },
                    }
                  );

                  console.log(emailResponse.data);
                } catch (error) {
                  console.log(error.message);
                }
              } else alert("Merci de bien vouloir remplir le formulaire");
            }}
            className="contact-form-submit-button"
            sx={{
              height: "44px",
              backgroundColor: "#0077",
              borderRadius: 100,
              fontSize: 25,
            }}
          >
            Valider
          </Button>
        </div>
        {/* <p className="solution-contact-form-text">
          Pour concevoir avec nos experts une solution qui répond à toutes vos
          attentes
        </p> */}
      </div>
      {/* <div className="contact-us-form-container">Hello</div> */}
      {/* </div> */}

      {/* <button className="payment-success-button" onClick={handleRedirect}>
        Go to Home Page
      </button>
      <button className="payment-success-button" onClick={handleRecommend}>
        Recommend to a Friend
      </button> */}
      {/* </div> */}
    </div>
  );
};

export default Home;
