import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Home from "./containers/Home";

// import Listings from "./containers/Listings";

function App() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const location = useLocation();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home data={data} isLoading={isLoading} />} />
      </Routes>
    </Router>
  );
}

export default App;
